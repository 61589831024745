import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Button, Flex } from '@components/common';
import { Caption, LabelText } from '@components/typography';
import {
	ADD_TO_CART,
	CUSTOMER_JOURNEYS,
	FRAME_COLORS,
	LENS_COLORS,
	LENSES_PACKAGES,
	LOCALE_DICT,
	NEW_BASE_FRAME_NAMES,
	RE_APPLY_RX_TYPE_TEXT,
	SUBMISSION_METHODS,
	UPDATE_CART,
} from '@constants';
import useBaseFrameVariant, { createBaseFrameVariant } from '@services/shopify/hooks/useBaseFrameVariant';
import { PreviousBaseFrame } from '@ts/re-apply-lenses';
import { BASE_FRAME_LENS_OPTIONS, BaseVariantPriceDictionary, RX_TYPE } from '@utils/constants/base-skus';
import { getLineItemPropertyForRxType } from '@utils/generate-bundle';
import { getFrameHandleFromColor } from '@utils/strings';
import { NormalizedProduct } from '@ts/index';
import { useBaseFrameCartManager } from '@utils/index';
import styles from './ReApplyLenses.module.scss';

type ReApplyLensesCardProps = {
	handle: string;
	frame: NEW_BASE_FRAME_NAMES;
	frameColor: string;
	previousBaseFrame: PreviousBaseFrame;
	variantPrices?: BaseVariantPriceDictionary;
	journey: CUSTOMER_JOURNEYS;
	lensColor?: LENS_COLORS;
	readerRx?: string;
	product: NormalizedProduct;
};

const ReApplyLensesCard = ({
	frameColor,
	handle,
	previousBaseFrame,
	variantPrices,
	journey,
	frame,
	lensColor,
	readerRx,
	product,
}: ReApplyLensesCardProps) => {
	const { locale, query } = useRouter();

	const lenses = [...previousBaseFrame.lensType];
	if (lensColor) {
		lenses.push(`Sun - ${lensColor}` as BASE_FRAME_LENS_OPTIONS);
	}
	const lens = lenses.length ? createBaseFrameVariant(lenses) : 'None';

	const BaseFrameVariant = useBaseFrameVariant({
		'handle': handle + `-${getFrameHandleFromColor(frameColor as FRAME_COLORS)}`,
		'Color': frameColor as FRAME_COLORS,
		'Rx Type': previousBaseFrame.rxType,
		'Lens': lens,
		'country': LOCALE_DICT[locale].countryCode,
	});

	const price = useMemo(() => {
		const basePrice = `${frameColor} / ${RX_TYPE.SINGLE_VISION} / ${LENSES_PACKAGES.BASIC}`;
		const newVariant = `${frameColor} / ${previousBaseFrame.rxType} / ${lens}`;
		if (!variantPrices?.[newVariant]) return 0;
		return variantPrices?.[newVariant]?.amount - variantPrices?.[basePrice]?.amount;
	}, [frameColor, variantPrices, lens]);

	const demo = query.demo as string;
	const isEditMode = Boolean(query?.edit);

	const handleClick = useBaseFrameCartManager({
		demo,
		frame,
		frameColor: frameColor as FRAME_COLORS,
		journey,
		lensColor,
		rxType: previousBaseFrame.rxType,
		readerRx,
		product,
		submissionMethod: SUBMISSION_METHODS.REMIND,
		BaseFrameVariant,
		isBuildFlow: false,
	});

	const label = `${
		RE_APPLY_RX_TYPE_TEXT[getLineItemPropertyForRxType(previousBaseFrame.rxType)]
	} ${previousBaseFrame.lensType.length ? createBaseFrameVariant(previousBaseFrame.lensType) : previousBaseFrame.lensPackage}`;

	const dataTags = isEditMode
		? {
				'data-reapply-lens-update-cart': `${frame} ${label}`,
			}
		: {
				'data-add-to-cart': `${frame} `,
				'data-reapply-lens-add-to-cart': `${frame} ${label}`,
			};

	return (
		<Flex column align='start' className={styles.card}>
			<LabelText>
				{RE_APPLY_RX_TYPE_TEXT[getLineItemPropertyForRxType(previousBaseFrame.rxType)]} +{' '}
				{previousBaseFrame.lensType.length
					? createBaseFrameVariant(previousBaseFrame.lensType)
					: previousBaseFrame.lensPackage}{' '}
				{'Lenses'}
			</LabelText>
			<Flex fullWidth className={styles.footer} align='center' justify='between'>
				<Button size='xsmall' color='white' extraClasses={styles.button} onClick={handleClick} dataTags={dataTags}>
					{isEditMode ? UPDATE_CART : ADD_TO_CART}
				</Button>
				<Caption small>+{price}</Caption>
			</Flex>
		</Flex>
	);
};

export default ReApplyLensesCard;
