import React from 'react';
import { Body, Detail, Title } from '@components/typography';
import { NormalizedProduct, PreviousBaseFrame } from '@ts/index';
import { BaseVariantPriceDictionary } from '@utils/constants/base-skus';
import { CUSTOMER_JOURNEYS, LENS_COLORS, NEW_BASE_FRAME_NAMES } from '@constants';
import { Divider } from '@components/common';
import variables from '@styles/export.module.scss';
import styles from './ReApplyLenses.module.scss';
import ReApplyLensesCard from './ReApplyLensesCard';

type ReApplyLensesProps = {
	handle: string;
	frame: NEW_BASE_FRAME_NAMES;
	frameColor: string;
	previousBaseFrames: PreviousBaseFrame[];
	variantPrices?: BaseVariantPriceDictionary;
	journey: CUSTOMER_JOURNEYS;
	lensColor?: LENS_COLORS;
	readerRx?: string;
	product: NormalizedProduct;
};

const ReApplyLenses = ({ frame, frameColor, previousBaseFrames, ...rest }: ReApplyLensesProps) => {
	if (!previousBaseFrames?.length) return null;

	const cards = previousBaseFrames.slice(0, 2);
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<Title>Build From Previous Order</Title>
				<Body>
					Save time by applying lens selections from a previous order to The {frame} in {frameColor}
				</Body>
			</div>
			<div className={styles.content}>
				{cards.map((previousBaseFrame, index) => (
					<ReApplyLensesCard
						key={`reapply-card-${index}`}
						frameColor={frameColor}
						frame={frame}
						previousBaseFrame={previousBaseFrame}
						{...rest}
					/>
				))}
			</div>

			<div className={styles.divider}>
				<Divider color={variables.gray3} alignment='horizontal' />
				<Detail>or</Detail>
				<Divider color={variables.gray3} alignment='horizontal' />
			</div>
		</div>
	);
};

export default ReApplyLenses;
